.kite-switch {
  position: relative;
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
}

.kite-switch:focus {
  outline: none;
}

.kite-switch:focus .kite-switch__track::after {
  content: '';
  position: absolute;
  top: -5px;
  left: -5px;
  width: calc(100% + 10px);
  height: calc(100% + 10px);
  border: 2px solid #63738a;
  border-radius: 20px;
}

.kite-switch--on:focus .kite-switch__track::after {
  content: '';
  position: absolute;
  top: -5px;
  left: -5px;
  width: calc(100% + 10px);
  height: calc(100% + 10px);
  border: 2px solid #0073d1;
  border-radius: 20px;
}

.kite-switch__inner {
  position: relative;
}

.kite-switch__track {
  position: relative;
  top: 1px;
  display: inline-block;
  width: 51px;
  background-color: #63738a;
  border: 2px solid #63738a;
  border-radius: 20px;
  transition: all 0.3s;
}

.kite-switch__toggle {
  display: block;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 1px 1px 3px #63738a;
  transition: all 0.3s;
}

.kite-switch__track-label {
  display: inline-block;
  position: absolute;
  top: 8px;
  pointer-events: none;
  font-size: 9px;
  font-weight: 700;
  line-height: 1em;
  color: #fff;
  transition: all 0.3s;
}

.kite-switch__track-label--off {
  right: 2px;
}

.kite-switch__track-label--on {
  left: 3px;
  opacity: 0;
}

.kite-switch__label {
  bottom: 0.5em;
}

.kite-switch--on {
  .kite-switch__track {
    background-color: #0073d1;
    border-color: #0073d1;
  }

  .kite-switch__track .kite-switch__toggle {
    transform: translateX(22px);
  }

  .kite-switch__track .kite-switch__track-label--off {
    opacity: 0;
  }

  .kite-switch__track .kite-switch__track-label--on {
    opacity: 1;
  }
}
