#g-recaptcha {
  display: inline-block;
}

.login-form-max-width {
  max-width: 520px;
}

.login-error-icon-max-width {
  max-width: 18px;
}

.login-title {
  font-size: 1.5rem;
}
