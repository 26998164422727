.tab .p {
  cursor: pointer;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.tab a {
  color: #000;
  text-decoration: none;
}

.tab.active {
  font-weight: 700;
  border-bottom: 2px solid #0073d1;
}

.tabs {
  border-bottom: 1px solid #d8dde6;
}

tr:first-child td {
  border: none;
}
