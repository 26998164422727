.sub-account-list {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;

  .userListItem:first-child {
    padding-top: 0;
    margin-top: -12px;
  }

  .userListItem a {
    border-radius: 4px;
    color: #67778f;
    display: block;
    width: 100%;
    padding: 8px 14px 8px 14px;
    margin-left: -14px;
  }

  .userListItem a:hover,
  .userListItem a:focus {
    background-color: #d8dde6;
    display: block;
    transition: background-color 0.5s ease-out;
    outline: none;
    text-decoration: none;
  }

  .userListItem.sub-account-active a {
    color: #002133;
    font-weight: bold;
  }
}
