.spinner-wrapper {
  position: fixed;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1048576;
  visibility: visible;
  opacity: 1;

  &.hidden {
    visibility: hidden;
    opacity: 0;
    transition: visibility 1s, opacity 0.8s ease-in-out;
  }

  &.statusFadeOut {
    visibility: hidden;
    opacity: 0;
    transition: visibility 1s, opacity 0.8s ease-in-out;
    transition-delay: 3s;
  }

  .messages {
    top: 50%;
    transform: translateY(-50%);
    font-size: 1.75rem;
    position: relative;
  }

  .secondary-messages {
    top: 50%;
    transform: translateY(-50%);
    font-size: 1.25rem;
    position: relative;
  }

  .confirmationButton {
    top: 50%;
    transform: translateY(-40%);
    position: relative;
  }
}

.spinner-overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0.92;
}

.loader-icons {
  position: relative;
  width: 80px;
  height: 80px;
  top: 50%;
  transform: translateY(-50%);
  margin: auto;
}

.status-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  opacity: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  &.fadeIn {
    animation: status-fade-in 0.3s ease-in-out, status-icon-rotate 0.3s ease-in-out;
    animation-fill-mode: forwards;
  }

  @keyframes status-fade-in {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes status-icon-rotate {
    0% {
      transform: rotate(-8deg);
    }

    100% {
      transform: rotate(0deg);
    }
  }
}

.spinner {
  top: 0;
  left: 0;
  position: absolute;
  animation: spinner-rotator 1.4s linear infinite;

  &.stopped {
    animation-iteration-count: 20;
  }

  .path {
    transition-delay: 0s;
    stroke-dasharray: 187;
    stroke-dashoffset: 46.75;
    transform-origin: center;
    animation: spinner-dash 1.4s ease-in-out infinite;

    &.spinner-fade-out {
      visibility: hidden;
      opacity: 0;
      transition: visibility 0.3s ease-in-out 0.5s, opacity 0.3s ease-in-out 0.5s, stroke 0.25s ease-in-out;
    }
  }

  &.stopped .path {
    animation-iteration-count: 20;
  }

  @keyframes spinner-rotator {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(270deg);
    }
  }

  @keyframes spinner-dash {
    0% {
      stroke-dashoffset: 187;
    }

    50% {
      stroke-dashoffset: 46.75;
      transform: rotate(135deg);
    }

    100% {
      stroke-dashoffset: 187;
      transform: rotate(450deg);
    }
  }
}
